import illustration1_de from "../Assets/images/answer images/Wohin mit dem Plastikmüll/verwertungspyramideneu_01_Zeichenfläche 1.svg"
import illustration1_en from "../Assets/images/answer images/Wohin mit dem Plastikmüll/hierarchy.svg"
import * as illustration2 from "../Assets/images/answer images/Wohin mit dem Plastikmüll/02_bioplastic980.jpg"
import * as illustration3 from "../Assets/images/answer images/Wohin mit dem Plastikmüll/03_Jasmin Sessler_Unsplash980.jpg"
import * as illustration4 from "../Assets/images/answer images/Wohin mit dem Plastikmüll/04_christine-sandu_unsplash980.jpg"
import * as illustration5 from "../Assets/images/answer images/Wohin mit dem Plastikmüll/05_garbage-2729608_1920_980.jpg"
import {quiz} from "../Translator/content";


export const QuestionSet_WohinMitDemPlastikmull = {
    Category: quiz.category2Name,
    questionSet: [
        {//1
            question: quiz.category2_question1,
            options: [quiz.category2_question1_option1, quiz.category2_question1_option2, quiz.category2_question1_option3],
            answer: 3,
            solution: {
                illustration: {en: illustration1_en, de: illustration1_de},
                illustrationSource: quiz.category2_question1_source,
                explanation: {
                    text: quiz.category2_question1_explanation,
                    images: [],
                    references: {
                        text: ["Mederake, Hinzmann & Langsdorf 2020"],
                        link: ["https://bmbf-plastik.de/sites/default/files/2020-08/20-08-04_Hintergrundpapier_Plastikpolitik-final.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//2
            question: quiz.category2_question2,
            options: [quiz.category2_question2_option1, quiz.category2_question2_option2, quiz.category2_question2_option3],
            answer: 1,
            solution: {
                illustration: illustration2,
                illustrationSource: quiz.category2_question2_source,
                explanation: {
                    text: quiz.category2_question2_explanation,
                    images: [],
                    references: {
                        text: quiz.category2_question2_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined
        },
        {//3
            question: quiz.category2_question3,
            options: [quiz.category2_question3_option1, quiz.category2_question3_option2, quiz.category2_question3_option3],
            answer: 1,
            solution: {
                illustration: illustration3,
                illustrationSource: quiz.category2_question3_source,
                explanation: {
                    text: quiz.category2_question3_explanation,
                    images: [],
                    references: {
                        text: quiz.category2_question3_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined
        },
        {//4
            question: quiz.category2_question4,
            options: [quiz.category2_question4_option1, quiz.category2_question4_option2, quiz.category2_question4_option3],
            answer: 2,
            solution: {
                illustration: illustration4,
                illustrationSource: quiz.category2_question4_source,
                explanation: {
                    text: quiz.category2_question4_explanation,
                    images: [],
                    references: {
                        text: ["Umweltbundesamt 2020"],
                        link: ["https://www.umweltbundesamt.de/themen/was-darf-nicht-in-die-toilette"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined
        },
        {//5
            question: quiz.category2_question5,
            options: [quiz.category2_question5_option1, quiz.category2_question5_option2, quiz.category2_question5_option3],
            answer: 2,
            solution: {
                illustration: illustration5,
                illustrationSource: quiz.category2_question5_source,
                explanation: {
                    text: quiz.category2_question5_explanation,
                    images: [],
                    references: {
                        text: ["Statista 2018 "],
                        link: ["https://de.statista.com/infografik/12419/so-viel-plastikmuell-verursachen-eu-buerger/"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined
        },
    ]
};