import React, {Component} from "react";
import Typography from "@material-ui/core/Typography";
import plastikNet from "../Assets/images/logo/PlastikNet@72ppi_0.jpg"
import bmbf_en from "../Assets/images/logo/BMBF_CMYK_Gef_L_e_en.png"
import bmbf_de from "../Assets/images/logo/BMBF_CMYK_Gef_L_e_de.png"
import fb_logo from "../Assets/icons/F_icon.svg"
import tw_logo from "../Assets/icons/Twitter_bird_logo_2012.svg"
import {isMobile} from "react-device-detect"
import {quiz} from "../Translator/content";

const colorStyle = {height: "10px",width: "20%", float: "left"};

class Footer extends Component{

  render() {
    return (
        <footer>
          <div style={{display: isMobile ? "block" : "flex", background: "white", padding: "10px 0"}}>
            <div style={{display: "flex", width: isMobile ? "" : "50%"}}>
              <div style={{width: "50%", margin: "auto"}}>
                <a href={`https://bmbf-plastik.de/${this.props.language}/plastiknet`} target={"_blank"}  rel="noopener noreferrer">
                  <img src={plastikNet}  alt={"plastiknet_logo"} style={{width: "60%"}}/>
                </a>
              </div>
              <div style={{width: "50%", margin: "auto"}}>
                <a href={"https://www.bmbf.de/"+this.props.language} target={"_blank"}  rel="noopener noreferrer">
                  <img src={this.props.language === "en" ? bmbf_en : bmbf_de} alt={"bmbf_logo"} style={{width: "60%"}}/>
                </a>
              </div>
            </div>
            {isMobile && <br/>}
            <div style={{width: isMobile ? "" : "50%", margin: "auto"}}>
              <Typography variant={"subtitle1"} style={{textAlign: "left", padding: "0 20px", color: "gray", fontSize: isMobile ? "0.8rem" : ""}}>
                {quiz.disclaimer[this.props.language]}
              </Typography>
            </div>
          </div>
          <div style={{display: isMobile ? "block" : "flex", background: "#C2DDE9", padding: "10px 0"}}>
            {/*<div style={{display: "flex", width: "30%", textAlign: "left", padding: "0 0 0 5%"}}>*/}
            <Typography variant={"subtitle1"} style={{textAlign: "left", color: "gray", padding: isMobile ? "0 20px" : "0 0 0 5%",width: isMobile ? "" : "30%", fontSize: isMobile ? "0.8rem" : ""}}>
              {quiz.shareQuiz[this.props.language]}
              <br/>
              {quiz.shareQuizOn[this.props.language]}&nbsp;&nbsp;
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI("http://quiz.bmbf-plastik.de/")}&title=${encodeURI("PlastikNet - Quiz")}`} style={{color: "grey"}}>
                <img src={fb_logo} alt={"FB logo"} style={{width:"20px", borderRadius: "50%"}}/>
                Facebook
              </a>&nbsp;&nbsp;
              <a href={`https://twitter.com/share?url=${encodeURI("http://quiz.bmbf-plastik.de/")}&text=${encodeURI("PlastikNet - Quiz")}`} style={{color: "grey"}}>
                <img src={tw_logo} alt={"Twitter logo"} style={{width:"20px", borderRadius: "50%"}}/>
                Twitter
              </a>&nbsp;
            </Typography>
            {/*</div>*/}
            {isMobile && <br/>}
            {!isMobile && <div style={{background: "grey", width: "2px"}}/>}
            <div style={{width: isMobile ? "" : "70%", margin: "auto"}}>
              <Typography variant={"subtitle1"} style={{textAlign: "left", padding: "0 20px", color: "gray", fontSize: isMobile ? "0.8rem" : ""}}>
                {quiz.quizIsFrom[this.props.language]}&nbsp;
                <a href={`https://www.ecologic.eu/${this.props.language === "en" ? "" : `de`}`} target={"_blank"} rel="noopener noreferrer" style={{color: "grey"}}>
                  {quiz.ecologicInstituteName[this.props.language]}
                </a>&nbsp;{quiz.quizIsFrom_additional[this.props.language]}
              </Typography>
            </div>
          </div>
        </footer>
    )
  }
}

export default Footer;