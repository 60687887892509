import React, {Component} from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import "./QuestionCard.css"
import Tooltip from "@material-ui/core/Tooltip";
import SummaryPlot from "./SummaryPlot";
import rightArrow from "../../Assets/icons/Polygon 2.svg";
import rightArrow_disabled from "../../Assets/icons/Polygon 2 disabled.svg";
import leftArrow from "../../Assets/icons/Polygon 1.svg";
import leftArrow_disabled from "../../Assets/icons/Polygon 1 disabled.svg";
import resetVideo from "../../Assets/FischeGrafik_05_2.mp4";
import {isMobile} from "react-device-detect";
import {quiz} from "../../Translator/content";
import Button from "@material-ui/core/Button";

class QuestionCards extends Component {

    constructor(props) {
        super(props);
        this.state={
            language: this.props.language(),
            mobileMode: isMobile,
            wrongAnswer: undefined,
            correctAnswer: undefined,
            flip: false,
            hide: false,
            showVideo: false,
            animationOn: false,
            fullWidth: true,
            partialWidth: false
        }
    };

    componentDidMount() {
        this.resizeHandler();
        window.addEventListener('resize', () => this.resizeHandler(), false)
    }

    resizeHandler = () => {
        if(window.innerWidth > 1600)
            this.setState({fullWidth: true, partialWidth: false});
        else if (window.innerWidth > 1350)
            this.setState({fullWidth: false, partialWidth: true});
        else
            this.setState({fullWidth: false, partialWidth: false});
    }

    nextQuestion = () => {
        if(!this.state.animationOn) {
            if(this.props.currentQuestion === this.props.totalQuestions+1){
                if(!this.props.allQuestionsAnswered()){
                    this.props.close();
                }
                else {
                    this.setState({showVideo: true})
                }
            }
            else{
                this.switchQuestions(false, true)
            }
        }
    };


    switchQuestions = (previous, next) => {
        this.setState({correctAnswer: undefined, flip: false});
        if(next){
            this.props.nextQuestion();
        }
        else {
            this.props.previousQuestion();
        }
    };

    setCSS = (index,flag) => {
        if(this.state.correctAnswer === undefined){
            if(flag){
                if(this.state.wrongAnswer === undefined){
                    if(this.props.question.answer === index+1){
                        this.props.setRightOrWrong(true);
                        this.setState({correctAnswer: index, animationOn: true});
                    }
                    else{
                        this.setState({wrongAnswer: index, animationOn: true});
                        this.props.setRightOrWrong(false);
                    }
                }
            }
            else{
                if(this.props.question.answer !== index+1){
                    this.setState({wrongAnswer: undefined, correctAnswer: undefined, animationOn: false});
                }
            }
        }
        else if(this.state.correctAnswer+1 === this.props.question.answer){
            this.setState({flip: true, animationOn: false}); this.props.answered();
        }
    };

    getAnswer = (answer) => {
        let answerArray = answer.split("CO2");
        return (
            <>
                {answerArray.length !== 1
                    ?
                    answerArray.map((answer, index) =>
                        <>
                            {answer}
                            {(index !== answerArray.length - 1) ? <>CO<sub>2</sub></> : ""}
                        </>)
                    :
                    answer
                }
            </>
        );
    };

    render() {
        return (
            <div className={"quizContainer"}>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Tooltip title={quiz.backToCategory[this.props.language()]}>
                        <div onClick={() => this.props.close()} style={{cursor: "pointer", width: "150px", display: "flex"}}>
                            {/*<div style={{display: "flex", flexDirection: "row-reverse"}}>*/}
                            <div className={"back_to_category"} style={{background: "#006E9E"}}/>
                            <div className={"back_to_category"} style={{background: "#85B541"}}/>
                            <div className={"back_to_category"} style={{background: "#FDC700"}}/>
                            {/*</div>*/}
                            {/*<div style={{display: "flex", flexDirection: "row-reverse", margin: "0 15px 0 0"}}>*/}
                            <div className={"back_to_category"} style={{background: "#54C2E4"}}/>
                            <div className={"back_to_category"} style={{background: "#9D4711"}}/>
                            {/*</div>*/}
                        </div>
                    </Tooltip>
                </div>
                <Typography variant={"body1"} style={{padding: "0 0 1% 0", color: "white"/*this.props.questionBackground*/}}>
                    {!this.state.showVideo
                        ?
                        this.props.currentQuestion !== this.props.totalQuestions + 1
                            ?
                            `${this.props.question.currentQuestion.label} ${this.props.currentQuestion} ${this.props.question.currentQuestion.between} ${this.props.totalQuestions}`
                            :
                            ""
                        :
                        quiz.quizCompleted[this.props.language()]
                    }
                </Typography>
                <Card style={{borderRadius: "30px", borderColor: this.props.questionBackground, borderStyle: "solid", borderWidth: "5px"}}>
                    {this.state.showVideo &&
                    <div style={{background: this.props.questionBackground, display: "flex", justifyContent: "center"}}>
                        <video muted autoPlay style={{width: "100%"}}>
                            <source src={resetVideo} type={"video/mp4"}/>
                        </video>
                        <Button style={{position: "absolute", top: "90%", fontSize: "medium"}} variant={"outlined"} color={"primary"} onClick={() => this.props.resetQuiz()}>
                            {quiz.startQuizAgain[this.props.language()]}
                        </Button>
                    </div>
                    }
                    {!this.state.showVideo && <CardContent style={{padding: "0px"}}>
                        <section id={"question"}>
                            <div style={{background: this.props.questionBackground, filter: "saturation(0.4)", color: "white", display: "flex", justifyContent: "space-between", padding: "20px 0 0 0", borderStyle: "solid", borderColor: this.props.questionBackground}}>
                                <img alt={"previous"} src={this.props.currentQuestion !== 1 && !this.state.animationOn ? leftArrow : leftArrow_disabled} style={{width: "60px", cursor: this.props.currentQuestion !== 1 ? "pointer" : ""}}
                                     onClick={() => {
                                         if(this.props.currentQuestion !== 1 && !this.state.animationOn)
                                             this.switchQuestions(true, false)
                                     }}
                                />
                                {this.props.summaryCardVideo !== undefined && this.props.currentQuestion === this.props.totalQuestions + 1
                                    ?
                                    <video autoPlay style={{width: "130px", borderRadius: "50%"}}>
                                        <source src={this.props.summaryCardVideo} type={"video/mp4"}/>
                                    </video>
                                    :
                                    <img alt={"logo"} src={this.props.logo} style={{width: "130px"}}/>
                                }
                                <img alt={"next"} src={(this.props.question.answered || this.props.currentQuestion === this.props.totalQuestions + 1) ? rightArrow : rightArrow_disabled}
                                     style={{width: "60px", cursor: (this.props.question.answered || this.props.currentQuestion === this.props.totalQuestions + 1) ? "pointer" : ""}}
                                     onClick={() => {
                                         if(this.props.question.answered || this.props.currentQuestion === this.props.totalQuestions + 1)
                                             this.nextQuestion();
                                     }}
                                />
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1439 119" style={{marginTop: -3, display: "block", marginBottom: "-3%"}}>
                                <path fill={this.props.questionBackground} fillOpacity="1" style={{transform: "scaleY(0.5)"}}
                                      d="M0,32L120,53.3C240,75,480,117,720,117.3C960,117,1200,75,1320,53.3L1440,32L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
                                />
                            </svg>
                            <Typography variant={"h6"} style={{padding: "20px", fontSize: "1.6rem", color: this.props.questionBackground, background: this.props.question.answered ? "rgba(0, 110, 158, 0.1)" : ""}}>
                                {
                                    this.props.currentQuestion !== this.props.totalQuestions +1
                                        ?
                                        this.getAnswer(this.props.question.currentQuestion.question)
                                        :
                                        this.props.answeredCorrectlyAtFirstGo
                                }
                                {this.props.currentQuestion !== this.props.totalQuestions + 1 && this.props.question.currentQuestion.questionSubtitle !== undefined &&
                                <Typography variant={"subtitle1"} style={{fontSize: isMobile ? "0.75rem" : "0.9rem", color: this.props.questionBackground}}>
                                    {this.getAnswer(this.props.question.currentQuestion.questionSubtitle)}
                                </Typography>}
                            </Typography>
                        </section>
                        <section id={"answer"}>
                            <section id={"options"}>
                                {!this.props.question.answered && this.props.currentQuestion !== this.props.totalQuestions + 1  &&
                                <div flip={this.state.flip.toString()} className={"flipOut"}>
                                    <div id={"options"} style={{padding: "20px", textAlign: "start", display: "flex", flexDirection: "column"}}>
                                        {this.props.question.options.options.map((answer,index) => {
                                            return(
                                                <>
                                                    <div key={"options" + index+1} className={"chip"}
                                                         wrong={this.state.wrongAnswer === index ? "true" : "false"}
                                                         correct={this.state.correctAnswer === index ? "true" : "false"}
                                                         onClick={() => {this.setCSS(index,true)}}
                                                         onAnimationEnd={() => this.setCSS(index, false)}>
                                                        <Typography variant={"h6"} className={"text-span"}
                                                                    style={{fontSize: isMobile ? "1.9rem" : "1.4rem"}}
                                                        >
                                                            {this.getAnswer(answer)}
                                                        </Typography>
                                                        <span className={"ripple-effect"}/>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>}
                            </section>
                            <section id={"explanation"}>
                                {this.props.question.answered &&
                                <div flip={this.props.question.answered.toString()} className={"flipIn"}>
                                    {!this.state.fullWidth && <>
                                        <Typography style={{textAlign: "left"}}>
                                            <b>{this.props.question.solution.correctAnswerLabel}</b>: {this.getAnswer(this.props.question.options.options[this.props.question.answer - 1])}
                                        </Typography>
                                        <br/>
                                    </>}
                                    <div style={{display: (this.state.fullWidth || this.state.partialWidth) ? "flex" : "inherit"}}>
                                        <Tooltip title={isMobile ? "" : this.props.question.solution.illustrationSource}>
                                            <div style={{cursor: "pointer", width: (this.state.fullWidth || this.state.partialWidth) ? "50%" : "100%", margin: "auto"}}>
                                                <img className={"answerImage"}
                                                     src={this.props.question.solution.illustration}
                                                />
                                            </div>
                                        </Tooltip>
                                        {isMobile && <span style={{fontSize: "10px"}}>
                                            {this.props.question.solution.illustrationSource}
                                        </span>}
                                        {(this.state.fullWidth || this.state.partialWidth) && <div style={{padding: "0 0 0 15px", width: "49%", margin: "auto"}}>
                                            {this.state.fullWidth &&
                                            <>
                                                <Typography style={{textAlign: "left"}}>
                                                    <b>{this.props.question.solution.correctAnswerLabel}</b>: {this.getAnswer(this.props.question.options.options[this.props.question.answer - 1])}
                                                </Typography>
                                                <br/>
                                            </>
                                            }
                                            <Typography style={{textAlign: "left"}}>
                                                {this.getAnswer(this.props.question.solution.explanation.note.text)}
                                            </Typography>
                                            {this.state.fullWidth &&
                                            <>
                                                <br/>
                                                <div style={{display: "flex"}}>
                                                    <Typography variant={"h6"} style={{textAlign: "left", fontSize: isMobile ? "2rem" : ""}}>
                                                        <b>{this.props.question.solution.explanation.note.references.label}</b>:
                                                    </Typography>
                                                    {this.props.question.solution.explanation.note.references.text
                                                        .map((text, index) =>
                                                            this.props.question.solution.explanation.note.references.link[index] !== ""
                                                                ?
                                                                <>
                                                                    &nbsp;<div key={index} className={"chip"} style={{fontSize: isMobile ? "1.6rem" : "", padding: "5px", margin: 0, color: "black"}}
                                                                               onClick={() => window.open(this.props.question.solution.explanation.note.references.link[index],"_blank")}
                                                                >
                                                                    {` ${text}`}
                                                                </div>
                                                                </>
                                                                :
                                                                " " + text
                                                        )}
                                                </div>
                                            </>}
                                        </div>}
                                    </div>
                                    {!this.state.fullWidth &&
                                    <>
                                        {!this.state.partialWidth && <>
                                            <br/>
                                            <Typography style={{textAlign: "left"}}>
                                                {this.getAnswer(this.props.question.solution.explanation.note.text)}
                                            </Typography>
                                        </>}
                                        <br/>
                                        <div style={{display: isMobile ? "block" : "flex"}}>
                                            <Typography style={{textAlign: "left"}}>
                                                <b>{this.props.question.solution.explanation.note.references.label}</b>:&nbsp;
                                                {this.props.question.solution.explanation.note.references.text
                                                    .map((text, index) =>
                                                        this.props.question.solution.explanation.note.references.link[index] !== ""
                                                            ?
                                                            <a className={"chip"} style={{textDecoration: "none", color: "black", lineHeight: 2, fontSize: "0.9rem"}}
                                                               href={this.props.question.solution.explanation.note.references.link[index]} target={"_blank"}>
                                                                {/*    <div key={index} className={"chip"} style={{fontSize: isMobile ? "1.1rem" : "", padding: "5px", margin: 0, color: "black"}}*/}
                                                                {/*               onClick={() => window.open(this.props.question.solution.explanation.note.references.link[index],"_blank")}*/}
                                                                {/*>*/}
                                                                {` ${text}`}
                                                                {/*</div>*/}
                                                            </a>
                                                            :
                                                            " " + text
                                                    )}
                                            </Typography>
                                        </div>
                                        <br/>
                                    </>}
                                </div>
                                }
                            </section>
                        </section>
                        <section id={"SummaryPlot"}>
                            {this.props.currentQuestion === this.props.totalQuestions + 1 &&
                            <SummaryPlot language={() => this.props.language()}
                                         answered={this.props.totalQuestionsAnswered} totalQuestions={this.props.totalQuestions}
                                         completionColor={this.props.questionBackground} graphDetails={this.props.graphDetails}
                            />
                            }
                        </section>
                    </CardContent>
                    }
                </Card>
            </div>
        );
    }
}

export default QuestionCards;