import React,{Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import * as bundesministerium from "../Assets/images/logo/Logo_Gefoerdert_vom_BMBF_CMYK_Gef_L_300dpi.jpg"
import {quiz} from "../Translator/content";

class Help extends Component {

    render() {
        return (
            <Dialog open={true} onClose={() => this.props.close()}>
                <div style={{padding: "4%", textAlign: "end"}}>
                    <Typography variant={"h6"} style={{textAlign: "center", fontWeight: 500, color: "grey", padding: "0 0 5% 0"}}>
                        {quiz.helpText1[this.props.language]}
                        <br/>
                        {quiz.helpText2[this.props.language]}
                        <br/>
                        <br/>
                        {quiz.helpText3[this.props.language]}
                        <br/>
                        <span style={{color: "#006E9E"}}>{quiz.category1Name[this.props.language]}</span>
                        <br/>
                        <span style={{color: "#85B541"}}>{quiz.category2Name[this.props.language]}</span>
                        <br/>
                        <span style={{color: "#FDC700"}}>{quiz.category3Name[this.props.language]}</span>
                        <br/>
                        <span style={{color: "#54C2E4"}}>{quiz.category4Name[this.props.language]}</span>
                        <br/>
                        <span style={{color: "#9D4711"}}>{quiz.category5Name[this.props.language]}</span>
                        <br/>
                        <br/>
                        {quiz.moreInfoText[this.props.language]}&nbsp;
                        <a href={"https://bmbf-plastik.de/"} target={"_blank"}  rel="noopener noreferrer" style={{color: "grey"}}>
                            Plastik in der Umwelt
                        </a>
                    </Typography>
                    <Button variant={"contained"} style={{background: "#1f817e", color: "white", marginLeft: "auto"}} onClick={() => this.props.close()}>{quiz.closeButtonLabel[this.props.language]}</Button>
                </div>
            </Dialog>
        );
    }

}

export default Help;