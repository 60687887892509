import * as illustration1 from "../Assets/images/answer images/Plastik in der Umwelt/01_cigerette butts_brian-yurasits980.jpg"
import * as illustration2 from "../Assets/images/answer images/Plastik in der Umwelt/02_Reifen_varun-gaba-r8FJ5wUT5FU-unsplash_quer980.jpg"
import * as illustration3 from "../Assets/images/answer images/Plastik in der Umwelt/03_Mond_martin-adams-unsplash980.jpg"
import * as illustration4 from "../Assets/images/answer images/Plastik in der Umwelt/04_martin-adams-r8lsgdwnRpU-unsplash_quer980.jpg"
import * as illustration5 from "../Assets/images/answer images/Plastik in der Umwelt/05_Lisa Fotios from Pexels_quer980.jpg"
import {quiz} from "../Translator/content";

export const QuestionSet_PlastikInDerUmwelt = {
    Category: quiz.category3Name,
    questionSet: [
        {//1
            question: quiz.category3_question1,
            options: [quiz.category3_question1_option1, quiz.category3_question1_option2, quiz.category3_question1_option3],
            answer: 1,
            solution: {
                illustration: illustration1,
                illustrationSource: quiz.category3_question1_source,
                explanation: {
                    text: quiz.category3_question1_explanation,
                    images: [],
                    references: {
                        text: ["Quarks 2019"],
                        link: ["https://www.quarks.de/umwelt/muell/auswirkungen-der-zigarettenkippen-auf-die-umwelt/"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//2
            question: quiz.category3_question2,
            options: [quiz.category3_question2_option1, quiz.category3_question2_option2, quiz.category3_question2_option3],
            answer: 1,
            solution: {
                illustration: illustration2,
                illustrationSource: quiz.category3_question2_source,
                explanation: {
                    text: quiz.category3_question2_explanation,
                    images: [],
                    references: {
                        text: quiz.category3_question2_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//3
            question: quiz.category3_question3,
            options: [quiz.category3_question3_option1, quiz.category3_question3_option2, quiz.category3_question3_option3],
            answer: 3,
            solution: {
                illustration: illustration3,
                illustrationSource: quiz.category3_question3_source,
                explanation: {
                    text: quiz.category3_question3_explanation,
                    images: [],
                    references: {
                        text: ["Mederake, Hinzmann, Langsdorf (2020)"],
                        link: ["https://bmbf-plastik.de/sites/default/files/2020-08/20-08-04_Hintergrundpapier_Plastikpolitik-final.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//4
            question: quiz.category3_question4,
            questionSubtitle: quiz.category3_question4Subtitle,
            options: [quiz.category3_question4_option1, quiz.category3_question4_option2, quiz.category3_question4_option3],
            answer: 2,
            solution: {
                illustration: illustration4,
                illustrationSource: quiz.category3_question4_source,
                explanation: {
                    text: quiz.category3_question4_explanation,
                    images: [],
                    references: {
                        text: quiz.category3_question4_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//5
            question: quiz.category3_question5,
            options: [quiz.category3_question5_option1, quiz.category3_question5_option2, quiz.category3_question5_option3],
            answer: 1,
            solution: {
                illustration: illustration5,
                illustrationSource: quiz.category3_question5_source,
                explanation: {
                    text: quiz.category3_question5_explanation,
                    images: [],
                    references: {
                        text: ["NABU 2020"],
                        link: ["https://www.nabu.de/umwelt-und-ressourcen/oekologisch-leben/alltagsprodukte/19463.html"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
    ]
};