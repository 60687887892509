import React,{Component} from "react";
import "./QuizCategory.css"
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import Footer from "../../Footer/Footer";

class QuizCategory extends Component{

    // positions = ["top", "middle-left", "middle-right", "bottom-left", "bottom-right"]


    constructor(props) {
        super(props);
    }

    render() {
        return  (
            <CardActionArea onClick={() => this.props.icon !== undefined && this.props.onClick()}
                            className={"card"}>
                <Card style={{filter: "saturate(40%)", background: this.props.titleBackground,borderRadius:"10%", borderColor: "white", borderStyle: "solid", borderWidth: "2px", minHeight: "350px", minWidth: "214px "}}>
                    <Typography variant={"subtitle1"} style={{ color: "white", padding: "1% 0", fontWeight: 600}}>
                        Status: {(this.props.questionsAnswered/this.props.totalQuestions)*100}%
                    </Typography>
                    <CardMedia style={{height: "250px", display: "flex", justifyContent: "center", borderColor: this.props.titleBackground, borderStyle: "solid"}}>
                        <img src={this.props.icon} style={{width: "60%"}}/>
                    </CardMedia>
                    <div>
                        {/*<Footer/>*/}
                        <CardContent className={"content"} style={{minHeight: this.props.titleMinHeight, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                            <Typography variant={"h5"} style={{color: "white", padding: "1% 0"}}>
                                {this.props.title}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            </CardActionArea>
        )
    }
}

export default QuizCategory;