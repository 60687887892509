import {QuestionSet_WohinMitDemPlastikmull} from "./subQuestionSet1";
import {QuestionSet_PlastikImAlltag} from "./subQuestionSet2";
import {QuestionSet_PlastikInDerUmwelt} from "./subQuestionSet3";
import {QuestionSet_FragenFurKinder} from "./subQuestionSet4";
import {QuestionSet_InnovationRundUmPlastik} from "./subQuestionSet5";
import {quiz} from "../Translator/content";
import fragenFurKinder from "../Assets/FragenFurKinderVideo.mp4"

const titleMinHeight = "64px";

export const quizQuestions = [
    {
        Category: quiz.category1Name,
        titleBackground: "#006E9E",
        titleMinHeight: titleMinHeight,
        color: "#1f817e",
        icon: require("../Assets/icons/IconsQuiz_trolley-06.svg"),
        // icon: require("../Assets/icons/shopping_01.svg"),
        questions: QuestionSet_PlastikImAlltag.questionSet,
        currentQuestion: 0,
        totalQuestionsAnswered: 0,
        answeredInFirstTry: 0,
        incorrectInFirstTry: 0,
    },
    {
        Category: quiz.category2Name,
        titleBackground: "#85B541",
        titleMinHeight: titleMinHeight,
        color: "#1f817e",
        icon:require("../Assets/icons/IconsQuiz_recycling-07.svg"),
        // icon:require("../Assets/icons/recycling_01.svg"),
        questions: QuestionSet_WohinMitDemPlastikmull.questionSet,
        currentQuestion: 0,
        totalQuestionsAnswered: 0,
        answeredInFirstTry: 0,
        incorrectInFirstTry: 0,
    },
    {
        Category: quiz.category3Name,
        titleBackground: "#FDC700",
        titleMinHeight: titleMinHeight,
        color: "#1f817e",
        icon: require("../Assets/icons/IconsQuiz_bottle-04.svg"),
        // icon: require("../Assets/icons/environment_01.svg"),
        questions: QuestionSet_PlastikInDerUmwelt.questionSet,
        currentQuestion: 0,
        totalQuestionsAnswered: 0,
        answeredInFirstTry: 0,
        incorrectInFirstTry: 0,
    },
    {
        Category: quiz.category4Name,
        titleBackground: "#54C2E4",
        titleMinHeight: titleMinHeight,
        color: "#1f817e",
        icon: require("../Assets/icons/IconsQuiz_child-05.svg"),
        questions: QuestionSet_FragenFurKinder.questionSet,
        summaryCardVideo: fragenFurKinder,
        currentQuestion: 0,
        totalQuestionsAnswered: 0,
        answeredInFirstTry: 0,
        incorrectInFirstTry: 0,
    },
    {
        Category: quiz.category5Name,
        titleBackground: "#9D4711",
        titleMinHeight: titleMinHeight,
        color: "#1f817e",
        icon: require("../Assets/icons/IconsQuiz_innovation-08.svg"),
        // icon: require("../Assets/icons/innovation_01.svg"),
        questions: QuestionSet_InnovationRundUmPlastik.questionSet,
        currentQuestion: 0,
        totalQuestionsAnswered: 0,
        answeredInFirstTry: 0,
        incorrectInFirstTry: 0,
    },
]