import React, {Component} from "react";
import ApexCharts from'apexcharts';

class SummaryPlot extends Component {

  constructor (props) {
    super (props);
    this.chartDiv = React.createRef();
    this.chart = undefined;
  }

  componentWillUnmount () {
    this.chart.destroy();
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
      this.chart.destroy();
      this.renderChart(this.props.language());
      return true;
  }

  renderChart = (language) => {
    let series = [], colors = [], label = [];
    this.props.graphDetails.completion.map(categoryDetails => {
      series.push(categoryDetails.answered);
      colors.push(categoryDetails.color);
      label.push(categoryDetails.label[language])
    });
    series.push(this.props.graphDetails.wrongInTheFirstTry.count);
    colors.push(this.props.graphDetails.wrongInTheFirstTry.color);
    label.push(this.props.graphDetails.wrongInTheFirstTry.label[language]);
    series.push(this.props.graphDetails.totalUnansweredQuestions.count);
    colors.push(this.props.graphDetails.totalUnansweredQuestions.color);
    label.push(this.props.graphDetails.totalUnansweredQuestions.label[language]);
    // let series = [this.props.answered, this.props.totalQuestions - this.props.answered];
    let options = {
      series: series,
      chart: {
        type: 'donut',
      },
      labels: label,
      plotOptions: {
        pie: {
          startAngle: -45
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (value) {
          return Math.round(value) + "%";
        }
      },
      colors: colors,
      fill: {
        // type: 'gradient',
        // colors: [this.props.completionColor, "#A9A9A9"]
      },
      legend: {
        position: 'right',
      },
    };
    this.chart = new ApexCharts(this.chartDiv.current, options);
    this.chart.render();
  };

  componentDidMount () {
    this.renderChart(this.props.language());
  }


  render () {
    return (
        <div style={{width: "90%", margin: "auto"}} ref={this.chartDiv}>
        </div>
    );
  }

}


export default SummaryPlot;