import React, { Component } from "react";
import QuizCategory from "./Category Cards/QuizCategory";
import Grid from "@material-ui/core/Grid";
import { quizQuestions } from "../Questions/QuizQuestions";
import QuestionCards from "./Question Cards/QuestionCards";
import "../Quiz/Quiz.css"
import Typography from "@material-ui/core/Typography";
import {isMobile} from "react-device-detect"
import {quiz} from "../Translator/content";
import * as _ from "lodash"


class Quiz extends Component{

    constructor(props) {
        super(props);
        this.state = {
            quizQuestions: _.cloneDeep(quizQuestions),
            help: true,
            openHelp: false,
            dialogContent: undefined,
            displayCategory: undefined,
            currentQuestion: undefined,
            nextQuestion: undefined,
            totalUnansweredQuestions: undefined,
            score: 0,
            totalQuestion: 0,
        };
    };

    componentDidMount() {
        this.setTotalQuestions();
    };

    setTotalQuestions = () => {
        let totalQuestion = 0;
        this.state.quizQuestions.map(quiz => totalQuestion += quiz.questions.length);
        this.setState({totalQuestion: totalQuestion})
    };

    resetQuiz = () => {
        this.closeQuestionCard();
        this.setState({
            quizQuestions: _.cloneDeep(quizQuestions),
            help: true,
            openHelp: false,
            dialogContent: undefined,
            displayCategory: undefined,
            currentQuestion: undefined,
            nextQuestion: undefined,
            totalUnansweredQuestions: undefined,
            score: 0,
            totalQuestion: 0,
        });
        this.setTotalQuestions();
    };

    /*
     * TO change the state values
     * */
    changeState = (stateName, value) => {
        this.setState({[stateName]: value})
    };

    getOptions = (options) => {
        let optionArray = [];
        options.map(option => {
            optionArray.push(option[this.props.language()])
        });
        return optionArray;
    };

    /*
     * To get the question from a given category
     * */
    getQuestion = (currentQuestion) => {
        return {
            currentQuestion: {
                label: quiz.questionLabel[this.props.language()],
                question: currentQuestion.question[this.props.language()],
                questionSubtitle: currentQuestion.questionSubtitle === undefined ? undefined : currentQuestion.questionSubtitle[this.props.language()],
                between: quiz.questionBetween[this.props.language()]
            },
            options: {label: "Optionen", options: this.getOptions(currentQuestion.options)},
            answer: currentQuestion.answer,
            solution: {
                correctAnswerLabel: quiz.correctAnswerLabel[this.props.language()],
                illustration: currentQuestion.solution.illustration.en === undefined ? currentQuestion.solution.illustration : currentQuestion.solution.illustration[this.props.language()],
                illustrationSource: currentQuestion.solution.illustrationSource[this.props.language()],
                explanation: {label: "Erklärung", note: {
                        text: currentQuestion.solution.explanation.text[this.props.language()],
                        images: currentQuestion.solution.explanation.images,
                        references: {
                            label: quiz.referenceLabel[this.props.language()],
                            text: !Array.isArray(currentQuestion.solution.explanation.references.text) ? currentQuestion.solution.explanation.references.text[this.props.language()] : currentQuestion.solution.explanation.references.text,
                            link: !Array.isArray(currentQuestion.solution.explanation.references.link) ? currentQuestion.solution.explanation.references.link[this.props.language()] : currentQuestion.solution.explanation.references.link,
                        }
                    }}
            },
            answered: currentQuestion.answered,
        }
    };

    /*
     * To change answered in the quiz object and also to change the current question and next question based on user action
     * */
    changeQuestionAttributes = (switchAnswered, nextQuestion,prevQuestion) => {
        if(switchAnswered) {
            /*
             * Since the questions are of type array of object which contains array of object,
             * shallow copy is carried out followed by state update
             * */
            let quizQuestionsCopy = [...this.state.quizQuestions];
            let category = quizQuestionsCopy[this.state.displayCategory];
            category.totalQuestionsAnswered += 1; //increment the total score to display the answer
            let questionsCopy = [...category.questions];
            let currentQuestion = {...questionsCopy[this.state.currentQuestion]};
            currentQuestion.answered = true;
            questionsCopy[this.state.currentQuestion] = currentQuestion;
            category.questions = questionsCopy;
            quizQuestionsCopy[this.state.displayCategory] = category;
            this.setState( {quizQuestions: quizQuestionsCopy, score: this.state.score + 1});
            // this.props.increaseScore();
        }
        else if(nextQuestion) {
            this.setState(prevState => {
                return {
                    currentQuestion: prevState.nextQuestion,
                    nextQuestion: (prevState.nextQuestion + 1) % (this.state.quizQuestions[this.state.displayCategory].questions.length + 1)
                }
            })
        }
        else if(prevQuestion) {
            this.setState(prevState => {
                return {
                    currentQuestion: prevState.currentQuestion === 0 ? this.state.quizQuestions[this.state.displayCategory].questions.length-1 : Math.abs(prevState.currentQuestion - 1) % this.state.quizQuestions[this.state.displayCategory].questions.length,
                    nextQuestion: prevState.currentQuestion
                }
            })
        }
    };

    /*
     * To open the question card given the category is chosen
     * */
    openQuestionCard = (index) => {
        if(this.state.quizQuestions[index].questions.length !== 0) {
            this.changeState("displayCategory", index);
            this.changeState("currentQuestion", this.state.quizQuestions[index].currentQuestion);
            this.changeState("nextQuestion", (this.state.quizQuestions[index].currentQuestion + 1) % this.state.quizQuestions[index].questions.length);
            this.changeState("dialogContent", undefined);
        }
        else {
            alert("Die Fragen für diese Kategorie sind noch nicht importiert!!")
        }
    };


    /*
     *
     * */
    getUnansweredQuestion = (currentQuestion) => {
        let nextQuestion = (currentQuestion + 1) % this.state.quizQuestions[this.state.displayCategory].questions.length;
        while(nextQuestion !== currentQuestion) {
            if(!this.state.quizQuestions[this.state.displayCategory].questions[nextQuestion].answered) {
                return nextQuestion;
            }
            else {
                nextQuestion = (nextQuestion + 1) % this.state.quizQuestions[this.state.displayCategory].questions.length;
            }
        }
        return this.state.quizQuestions[this.state.displayCategory].questions.length;
    };

    /*
     * To close to question card and back to the category
     * */
    closeQuestionCard = () => {
        let quizQuestionCopy = [...this.state.quizQuestions];
        let quizObjectCopy = {...quizQuestionCopy[this.state.displayCategory]};
        if(this.state.currentQuestion !== quizObjectCopy.questions.length) {
            if (quizObjectCopy.questions[this.state.currentQuestion].answered) {
                quizObjectCopy.currentQuestion = this.getUnansweredQuestion (this.state.currentQuestion);
            }
            else {
                quizObjectCopy.currentQuestion = this.state.currentQuestion;
            }
        }
        else {
            quizObjectCopy.currentQuestion = this.getUnansweredQuestion ((this.state.currentQuestion-1) % this.state.quizQuestions[this.state.displayCategory].questions.length);
        }
        quizQuestionCopy[this.state.displayCategory] = quizObjectCopy;
        this.changeState("quizQuestions", quizQuestionCopy);
        this.changeState("displayCategory", undefined);
        this.changeState("dialogContent", undefined);
        this.changeState("currentQuestion", undefined);
        this.changeState("nextQuestion", undefined);
    };

    setRightOrWrong = (correctAnswer) => {
        let quizQuestionsCopy = [...this.state.quizQuestions];
        let questionCollectionCopy = [...quizQuestionsCopy[this.state.displayCategory].questions];
        let currentQuestion = {...questionCollectionCopy[this.state.currentQuestion]};
        if(correctAnswer && currentQuestion.wrongInTheFirstTry !== true) {
            currentQuestion.correctInTheFirstTry = true;
            currentQuestion.wrongInTheFirstTry = false;
            quizQuestionsCopy[this.state.displayCategory].answeredInFirstTry += 1;
        }
        else if(!correctAnswer && currentQuestion.wrongInTheFirstTry !== true && currentQuestion.correctInTheFirstTry !==true){
            currentQuestion.wrongInTheFirstTry = true;
            currentQuestion.correctInTheFirstTry = false;
            quizQuestionsCopy[this.state.displayCategory].incorrectInFirstTry += 1;
        }
        questionCollectionCopy[this.state.currentQuestion] = currentQuestion;
        quizQuestionsCopy[this.state.displayCategory].questions = questionCollectionCopy;
        this.setState({quizQuestions: quizQuestionsCopy})
    };

    getCompletionPercentage = () => {
        let completion = [];
        let totalUnansweredQuestions = this.state.totalQuestion;
        let totalWrongInTheFirstTry = 0;
        this.state.quizQuestions.map(category => {
            totalWrongInTheFirstTry += category.incorrectInFirstTry;
            totalUnansweredQuestions = totalUnansweredQuestions - category.answeredInFirstTry - category.incorrectInFirstTry;
            completion.push({label: category.Category, answered: category.answeredInFirstTry, color: category.titleBackground})
        });
        return {
            totalUnansweredQuestions: {count: totalUnansweredQuestions, label : {en: "Still open", de:"Noch offen"}, color: "#C1C1C1"},//#A9A9A9
            completion: completion,
            wrongInTheFirstTry: {count: totalWrongInTheFirstTry, label: {en: "Incorrectly answered", de: "Falsch beantwortet"}, color: "#616161"}
        }
    }

    render() {
        return (
            <div>
                {this.state.displayCategory === undefined &&
                <Typography variant={"h6"} style={{fontSize: isMobile ? "0.8rem" : "", fontWeight: 500, background: "white", color: "gray", padding: "1% 5%", textAlign: "left"}}>
                    {quiz.introduction[this.props.language()]}
                </Typography>}
                <div style={{padding: isMobile ? "5%" :"2% 23%", color: "gray", backgroundColor: "rgba(97, 97, 97, 0.59)"}}>
                    {this.state.displayCategory === undefined &&
                    <Grid container className={"categories"} spacing={4}
                          wrap={'wrap'}
                          style={{justifyContent: "space-around", borderRadius: "20%"}}>
                        {/*To render category cards*/}
                        {this.state.quizQuestions.map ((category, index) => {
                            return <Grid item sm={4} xs={4} key={category+index}
                                         style={{minWidth: "270px"}}>
                                <QuizCategory key={index}
                                              onClick={() => this.openQuestionCard (index)}
                                              icon={category.icon}
                                              iconPosition={category}
                                              title={category.Category[this.props.language()]}
                                              titleBackground={category.titleBackground}
                                              titleMinHeight={category.titleMinHeight}
                                              totalQuestions = {category.questions.length}
                                              questionsAnswered = {category.totalQuestionsAnswered}
                                />
                            </Grid>
                        })}
                    </Grid>
                    }
                    {/*To render the question cards*/}
                    {this.state.displayCategory !== undefined &&
                    <QuestionCards
                        close={() => this.closeQuestionCard()}
                        logo={this.state.quizQuestions[this.state.displayCategory].icon}
                        questionBackground={this.state.quizQuestions[this.state.displayCategory].titleBackground}
                        summaryCardVideo={this.state.quizQuestions[this.state.displayCategory].summaryCardVideo}
                        totalQuestions={this.state.quizQuestions[this.state.displayCategory].questions.length}
                        totalQuestionsAnswered={this.state.quizQuestions[this.state.displayCategory].totalQuestionsAnswered}
                        currentQuestion={this.state.currentQuestion + 1}
                        answered={() => this.changeQuestionAttributes(true, false,false)}
                        nextQuestion={() => this.changeQuestionAttributes(false,true, false)}
                        previousQuestion={() => this.changeQuestionAttributes(false,false, true)}
                        question={this.state.currentQuestion !== this.state.quizQuestions[this.state.displayCategory].questions.length
                            ?
                            this.getQuestion (this.state.quizQuestions[this.state.displayCategory].questions[this.state.currentQuestion])
                            :
                            ""
                        }
                        resetQuiz={() => this.resetQuiz()}
                        allQuestionsAnswered={() => this.state.score === this.state.totalQuestion}
                        graphDetails={this.getCompletionPercentage()}
                        setRightOrWrong = {(correctAnswer) => this.setRightOrWrong(correctAnswer)}
                        language={() => this.props.language()}
                        answeredCorrectlyAtFirstGo={quiz.answeredCorrectlyAtFirstGo[this.props.language()]}
                    />}
                </div>
            </div>
        )
    }
}

export default Quiz;