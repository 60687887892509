import React from "react";

export const quiz = {
    title1: {en: "Plastic in the environment", de: "Plastik in der Umwelt"},
    title2: {en: "What do you know?", de: "Was wissen Sie?"},
    helpTooltip: {en: "About the quiz", de: "Über das Quiz"},
    introduction: {en: "This quiz invites you to test your knowledge about plastics. Plastics are all around us and make up an essential part of our daily lives. But what do you know about this material, its diversity, the associated environmental problems and possible solutions?", de: "Mit diesem Quiz möchten wir Sie dazu einladen, Ihr Wissen rund um das Thema Plastik zu testen. Kunststoffe sind überall um uns herum und ein wesentlicher Bestandteil unseres täglichen Lebens. Was wissen Sie über dieses Material, seine Vielfalt, die damit verbundenen Umweltprobleme und Lösungsansätze?"},
    helpText1: {en: "This quiz was created as a part of the comprehensive research programme \"Plastics in the Environment\" (duration 2017-2022), funded by the German Federal Ministry of Education and Research (BMBF).", de: "Dieses Quiz wurde im Rahmen des Forschungsschwerpunkts „Plastik in der Umwelt“ (Laufzeit 2017-2022) erstellt, gefördert vom Bundesministerium für Bildung und Forschung (BMBF)."},
    helpText2: {en: "The authors are solely responsible for the contents of the quiz. The content does not reflect the official position of the BMBF.", de: "Für die Inhalte des Quiz sind allein die Autor*innen verantwortlich. Sie spiegeln nicht die offizielle Meinung des BMBF wider."},
    helpText3: {en: "The quiz has five categories, each with five questions:", de: "Das Quiz beinhaltet 5 Kategorien mit jeweils 5 Wissensfragen:"},
    moreInfoText: {en: "For more information visit:", de: "Weitere Informationen:"},
    closeButtonLabel: {en: "Close", de: "Schließen"},
    backToCategory: {en: "Back to the categories", de: "Zu den Kategorien"},
    disclaimer: {en: "This quiz was created as a part of the comprehensive research programme \"Plastics in the Environment\" (duration 2017-2022), funded by the German Federal Ministry of Education and Research (BMBF). The authors are solely responsible for the contents of the quiz. The content does not reflect the official position of the BMBF.", de: "Dieses Quiz wurde im Rahmen des Forschungsschwerpunkts „Plastik in der Umwelt“ (Laufzeit 2017-2022) erstellt, gefördert vom Bundesministerium für Bildung und Forschung (BMBF). Für die Inhalte des Quiz sind allein die Autor*innen verantwortlich. Sie spiegeln nicht die offizielle Meinung des BMBF wider."},
    shareQuiz: {en: "Share the quiz:", de: "Quiz teilen:"},
    shareQuizOn: {en: "on", de: "auf"},
    correctAnswerLabel: {en: "Correct answer", de: "Richtige Antwort"},
    referenceLabel: {en: "References", de: "Quellenangabe"},
    quizIsFrom: {en: "This quiz was developed and implemented by ", de: "Dieses Quiz wurde vom"},
    ecologicInstituteName: {en: "Ecologic Institute", de: "Ecologic Institut"},
    quizIsFrom_additional: {en: ". Concept & Content: Mandy Hinzmann, Janna Vakili | Programming: Prasanna Ramesh | Design: Lena Aebli", de: "entwickelt und umgesetzt. Konzept & Inhalte: Mandy Hinzmann, Janna Vakili | Programmierung: Prasanna Ramesh | Design: Lena Aebli"},
    questionLabel: {en: "Question", de: "Frage"},
    questionBetween: {en: "from", de: "von"},
    answeredCorrectlyAtFirstGo: {en: "Answered correctly at the first go" ,de: "Auf Anhieb richtig beantwortet"},
    quizCompleted: {en: "Quiz completed", de: "Quiz abgeschlossen"},
    startQuizAgain: {en: "Start the quiz again?", de: "Quiz neu beginnen?"},
    category1Name: {en: "Plastic in everyday life", de: "Plastik im Alltag"},
    category2Name: {en: "What happens to plastic waste?", de: "Wohin mit dem Plastikmüll"},
    category3Name: {en: "Plastic in the environment", de: "Plastik in der Umwelt"},
    category4Name: {en: "Questions for kids", de: "Fragen für Kinder & Erwachsene"},
    category5Name: {en: "Innovations around plastics", de: "Innovationen rund um Plastik"},
    category1_question1: {en: "When I wash a polyester fleece jacket can microplastics get into the water?", de: "Wenn ich eine Fleecejacke aus Polyester wasche, kann dadurch Mikroplastik in Gewässer gelangen?"},
    category1_question2: {en: "Which of these industrially produced fibers for the manufacture of synthetic textiles is not based on petroleum?", de: "Welche dieser industriell hergestellten Fasern für synthetische Textilien basiert nicht auf Erdöl?"},
    category1_question3: {en: "When did synthetically manufactured plastics become mass produced?", de: "Wann wurden synthetisch hergestellte Kunststoffe zu einem Massenprodukt?"},
    category1_question4: {en: "How has global plastic production developed over the past 20 years?", de: "Wie hat sich die weltweite Plastikproduktion in den vergangenen 20 Jahren entwickelt?"},
    category1_question5: {en: "Starting from 2021, which of the following single-use plastics (apart from drinking straws) will no longer be permitted on the European market?", de: "Welche der folgenden Einwegartikel aus Kunststoff werden – neben Trinkhalmen – ab dem Jahr 2021 nicht mehr auf dem europäischen Markt zugelassen?"},
    category1_question1_option1: {en: "No. Washing machines generally filter out microplastics.", de: "Nein, da Waschmaschinen normalerweise Mikroplastik herausfiltern."},
    category1_question1_option2: {en: "No. Sewage treatment plants generally filter out microplastics. ", de: "Nein, da Kläranlagen Mikroplastik normalerweise herausfiltern."},
    category1_question1_option3: {en: "Yes. Neither conventional washing machines nor wastewater treatment plants are able to filter out microplastics completely.", de: "Ja, denn weder herkömmliche Waschmaschinen noch Kläranlagen filtern Mikroplastik komplett heraus."},
    category1_question2_option1: {en: "Polyester", de: "Polyester"},
    category1_question2_option2: {en: "Lyocell (also known as Tencel)", de: "Lyocell (auch bekannt als Tencel)"},
    category1_question2_option3: {en: "Elasthan (also known as Lycra)", de: "Elasthan (auch bekannt als Lycra)"},
    category1_question3_option1: {en: "In the 1930s", de: "In den 1930er Jahren"},
    category1_question3_option2: {en: "In the 1950s", de: "In den 1950er Jahren"},
    category1_question3_option3: {en: "In the 1970s", de: "In den 1970er Jahren"},
    category1_question4_option1: {en: "It has slightly decreased.", de: "Sie ist leicht zurückgegangen."},
    category1_question4_option2: {en: "It has nearly doubled.", de: "Sie hat sich nahezu verdoppelt."},
    category1_question4_option3: {en: "It has tripled.", de: "Sie hat sich verdreifacht."},
    category1_question5_option1: {en: "Single-use cutlery", de: "Einwegbesteck"},
    category1_question5_option2: {en: "Balloons", de: "Luftballons"},
    category1_question5_option3: {en: "Plastic beverage bottles", de: "Getränkeflaschen"},
    category1_question1_source: {en: "Photo: Lena Aebli / Ecologic Institut", de: "Foto: Lena Aebli / Ecologic Institut"},
    category1_question2_source: {en: "Photo: Lena Aebli / Ecologic Institut", de: "Foto: Lena Aebli / Ecologic Institut"},
    category1_question3_source: {en: "Photo: Maxpixel.net", de: "Foto: Maxpixel.net"},
    category1_question4_source: {en: "Photo: Jonathan Chng / unsplash.com", de: "Foto: Jonathan Chng / unsplash.com"},
    category1_question5_source: {en: "Photo: Volodymyr Hryshchenko / unsplash.com", de: "Foto: Volodymyr Hryshchenko / unsplash.com"},
    category1_question1_explanation: {en: "Whenever synthetic garments are washed, fibrous microplastics (length < 5 mm) are released with each wash cycle. According to study results, up to 250,000 microplastic particles are released whenever a fleece jacket is washed. Washing machines generally do not have a filter function. Sewage treatment plants are able to filter to a great extent, but not all microplastics are eliminated. ", de: "Werden synthetische Kleidungsstücke gewaschen, löst sich bei jedem Waschgang faserförmiges Mikroplastik (Länge < 5 mm). Laut Studienergebnissen werden bei der Wäsche einer Fleecejacke bis zu 250.000 Teilchen Mikroplastik freigesetzt. Waschmaschinen haben üblicherweise keine Filterfunktion. Kläranlagen filtern zwar einen Großteil, aber auch nicht alles Mikroplastik heraus."},
    category1_question2_explanation: {en: "Lyocell is an industrially produced fiber made of cellulose. It is mainly sold under the name Tencel, which is made of processed wood fibers from sustainably managed forests or plantations. Lyocell is also biodegradable.", de: "Lyocell ist eine aus Cellulose bestehende, industriell hergestellte Faser. Sie wird hauptsächlich unter dem Namen Tencel verkauft und stammt dann aus aufbereiteten Holzfasern aus nachhaltig bewirtschafteten Wäldern oder Plantagen. Sie ist biologisch abbaubar."},
    category1_question3_explanation: {en: "Plastics became mass produced in the early 1950s, following the Second World War. Up to this point, people had generally been careful with plastic articles and packaging; however, this development increasingly led to the throwaway mentality that is still common today.", de: "Kunststoffe wurden nach dem Zweiten Weltkrieg in den frühen 1950er Jahren zum Massenprodukt. Während die Menschen bis dahin achtsam mit Kunststoffartikeln und -verpackungen umgingen, stellte sich im Zuge dieser Entwicklung zunehmend die heute noch verbreitete Wegwerfmentalität ein."},
    category1_question4_explanation: {en: "Global plastic production has almost doubled in the past 20 years. At present over 400 million tons are produced annually. This figure is expected to double again in the next 20 years.", de: "Die weltweite Plastikproduktion hat sich in den vergangenen 20 Jahren nahezu verdoppelt: Aktuell werden über 400 Millionen Tonnen jährlich produziert. Es wird erwartet, dass sich die Zahl in den nächsten 20 Jahren noch einmal verdoppeln wird."},
    category1_question5_explanation: {en: "Starting from July 2021, the EU will ban the marketing of certain disposable plastic articles, such as disposable cutlery, drinking straws, cotton swabs and polystyrene food containers in order to better protect the environment. These disposables are often thrown away carelessly, ending up in nature. More sustainable and affordable alternatives to all of these items are already available on the market.", de: "Zum besseren Schutz der Umwelt verbietet die EU ab Juli 2021 das Inverkehrbringen von bestimmten Einwegartikeln aus Kunststoff, wie beispielsweise Einwegbesteck, Trinkhalme, Wattestäbchen und Lebensmittelbehälter aus Styropor. Diese Einwegartikel enden häufig durch achtloses Wegwerfen in der Umwelt. Für sie alle gibt es bereits nachhaltigere und erschwingliche Alternativen auf dem Markt."},
    category1_question1_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 22-23"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 22-23"]},
    category1_question3_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 12-13"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 12-13"]},
    category1_question4_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 26"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 26"]},
    category2_question1: {en: "Which of the following measures for waste recycling is the best overall in ecological terms?", de: "Welche der folgenden Maßnahmen zur Abfallverwertung ist ökologisch gesehen insgesamt die beste?"},
    category2_question2: {en: "How does one dispose of biodegradable plastic bags?", de: "Wie sollten Abfallbeutel aus biologisch abbaubarem Plastik entsorgt werden?"},
    category2_question3: {en: "Why are many different types of plastic packaging difficult to recycle?", de: "Warum ist das Recycling vieler Lebensmittelverpackungen aus Kunststoff oftmals sehr schwierig?"},
    category2_question4: {en: "What is the correct disposal method for wet wipes?", de: "Wie entsorgt man feuchtes Toilettenpapier (Feuchttücher) richtig?"},
    category2_question5: {en: "How much plastic packaging waste do Germans generate on average per capita in one year?", de: "Wieviel Plastikverpackungsmüll verursachen die Deutschen im Durchschnitt pro Kopf in einem Jahr?"},
    category2_question1_option1: {en: "Recycling ", de: "Recycling"},
    category2_question1_option2: {en: "Energetic utilization (energy recovery)", de: " Energetische Verwertung (Energierückgewinnung)"},
    category2_question1_option3: {en: "Repair/cleaning and reuse", de: " Reparatur/Reinigung und Wiederverwendung"},
    category2_question2_option1: {en: "In the residual waste", de: "Im Restmüll"},
    category2_question2_option2: {en: "On the compost heap", de: " Auf dem Komposthaufen"},
    category2_question2_option3: {en: " In the organic waste", de: " Im Biomüll"},
    category2_question3_option1: {en: "Because they consist of different, multi-layered materials.", de: "Weil sie aus unterschiedlichen, mehrschichtigen Materialien bestehen."},
    category2_question3_option2: {en: "Because food residues can contaminate the material.", de: "Weil Lebensmittelreste das Material verunreinigen können."},
    category2_question3_option3: {en: " Because plastics, unlike paper or glass, are generally not well suited for recycling.", de: "Weil Kunststoffe im Gegensatz zu Papier oder Glas grundsätzlich nicht gut für ein Recycling geeignet sind."},
    category2_question4_option1: {en: "Flush them down the toilet.", de: "Über die Toilettenspülung."},
    category2_question4_option2: {en: "Place them in the residual waste.", de: " Über den Restmüll."},
    category2_question4_option3: {en: " Place them in the recycling bag or recycling bin.", de: " Über den gelben Sack bzw. die Werkstofftonne."},
    category2_question5_option1: {en: "24 kg", de: "24 Kilogramm"},
    category2_question5_option2: {en: "38 kg", de: "  38 Kilogramm"},
    category2_question5_option3: {en: " 52 kg", de: " 52 Kilogramm"},
    category2_question1_source: {en: "Source: Ecologic Institut", de: "Quelle: Ecologic Institut"},
    category2_question2_source: {en: "Photo: Lena Aebli / Ecologic Institut", de: "Foto: Lena Aebli / Ecologic Institut"},
    category2_question3_source: {en: "Photo: Jasmin Sessler / unsplash.com", de: "Foto: Jasmin Sessler / unsplash.com"},
    category2_question4_source: {en: "Photo: Christine Sandu / unsplash.com", de: "Foto: Christine Sandu / unsplash.com"},
    category2_question5_source: {en: "Photo: RitaE / pixaby", de: "Foto: RitaE / pixaby"},
    category2_question1_explanation: {en: "The EU has established a ranking of measures for the waste hierarchy. The higher-ranked measure is the ecologically better option. For example, waste prevention and preparation for re-use is preferable to recycling. However, exceptions to this ranking are possible, e.g. for reasons of technical feasibility.", de: "Die EU legt für die Abfallhierarchie eine Rangfolge für Maßnahmen fest. Die höhergestufte Maßnahme ist dabei die ökologisch bessere Option. So ist beispielsweise die Vermeidung von Abfällen und die Vorbereitung zur Wiederverwendung gegenüber dem Recycling zu bevorzugen. Ausnahmen von dieser Reihenfolge sind allerdings möglich, z. B. aus Gründen der technischen Durchführbarkeit."},
    category2_question2_explanation: {en: "Biodegradable plastic garbage bags are made of plastics that can only be biodegraded under certain conditions (sufficient temperature and time). These conditions are seldom reached in industrial composting plants and are not achieved in the household compost heap. Therefore, bags should be disposed of with the residual waste.", de: "Abfallbeutel aus biologisch abbaubarem Plastik bestehen aus Kunststoffen, die nur unter bestimmten Bedingungen (ausreichend Temperatur und Zeit) biologisch abgebaut werden können. Diese Bedingungen werden in industriellen Kompostieranlagen selten und im eigenen Komposthaufen nicht erreicht. Daher sollten die Beutel in der Restmülltonne entsorgt werden."},
    category2_question3_explanation: {en: "Food packaging often consists of different, multilayer materials. These are almost always unrecyclable under current practice. If they are recycled, mixed plastics are usually produced, which are of inferior quality compared to new products. ", de: "Lebensmittelverpackungen bestehen häufig aus unterschiedlichen, mehrschichtigen Materialien. Diese gelten nach heutigem Stand der Praxis als kaum recyclingfähig. Werden sie recycelt, entstehen meist Mischkunststoffe, die eine minderwertige Qualität gegenüber Neuware haben."},
    category2_question4_explanation: {en: "Wet wipes are tear-resistant and do not dissolve in water like conventional toilet paper. They often contain plastic fibers to ensure their tear resistance. Wet wipes in the sewage system lead to clogged pipes and can cripple pumps. The correct way to dispose of wet wipes is therefore via residual waste.", de: "Feuchttücher sind reißfest und lösen sich nicht wie herkömmliches Toilettenpapier im Wasser auf. Oft enthalten sie Fasern aus Kunststoff, um ihre Reißfestigkeit zu gewährleisten. Feuchttücher im Abwassersystem führen zu verstopften Leitungen und können Pumpen lahmlegen. Der richtige Weg, Feuchttücher zu entsorgen, ist daher über den Restmüll."},
    category2_question5_explanation: {en: "In 2016, each Germany citizen caused an average of about 38 kg of plastic packaging waste. This puts Germany well above the EU average of 24 kg.", de: "In Deutschland verursachte jede Bürgerin und jeder Bürger im Jahr 2016 im Durchschnitt etwa 38 kg Verpackungsabfälle aus Kunststoff. Damit liegt Deutschland deutlich über dem EU-Durchschnitt von 24 kg."},
    category2_question2_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 35"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 35"]},
    category2_question3_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 37"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 37"]},
    category3_question1: {en: "Cigarette filters are a particularly big problem for the environment. Why is this the case?", de: "Zigarettenfilter sind ein besonders großes Problem für die Umwelt. Warum ist das so?"},
    category3_question2: {en: "According to the current information available, what is the main source of microplastics released into the environment?", de: "Was ist nach heutigem Kenntnisstand die Hauptquelle von Mikroplastik, das in die Umwelt freigesetzt wird?"},
    category3_question3: {en: "Where have microplastic particles not yet been discovered?", de: "Wo wurden bislang noch keine Mikroplastikpartikel entdeckt?"},
    category3_question4: {en: "In 2015 approximately how many climate-damaging greenhouse gases were released due to the global production of plastics?", de: "Wie viele klimaschädliche Treibhausgase wurden schätzungsweise im Jahr 2015 durch die weltweite Herstellung von Plastik freigesetzt?"},
    category3_question4Subtitle: {en: "(For comparison: Germany released approx. 900 million tons of CO2 across all sectors in 2015.)", de: "(Zum Vergleich: Deutschland hat im Jahr 2015 über alle Sektoren hinweg ca. 900 Millionen Tonnen CO2 freigesetzt.)"},
    category3_question5: {en: "What is a poor alternative to disposable plastic bags from an ecological perspective?", de: "Was ist aus ökologischer Sicht keine gute Alternative zu Einwegplastiktüten?"},
    category3_question1_option1: {en: "Because the number of smokers in Germany is increasing every year and therefore more and more cigarette butts accumulate as garbage.", de: "Weil es üblich ist, die Zigarettenstummel nach dem Rauchen achtlos auf den Boden zu werfen."},
    category3_question1_option2: {en: "Because it is common practice to carelessly throw cigarette butts on the ground after smoking. ", de: "Weil die Anzahl der Raucherinnen und Raucher in Deutschland jedes Jahr ansteigt und dadurch immer mehr Zigarettenstummel als Müll anfallen."},
    category3_question1_option3: {en: "Because the fumes from public ashtrays significantly affect air quality.", de: "Weil die Ausdünstungen von öffentlichen Aschenbechern die Luftqualität erheblich beeinträchtigen."},
    category3_question2_option1: {en: "Tire abrasion", de: "Reifenabrieb"},
    category3_question2_option2: {en: "Plastic particles added to cosmetic products", de: "Zugesetzte Plastikpartikel in Kosmetikprodukten"},
    category3_question2_option3: {en: "Industrial pellets", de: "Industriepellets"},
    category3_question3_option1: {en: "In the Arctic ice", de: "Im arktischen Eis"},
    category3_question3_option2: {en: "In the air and soil", de: "In der Luft und im Boden"},
    category3_question3_option3: {en: "On the moon", de: "Auf dem Mond"},
    category3_question4_option1: {en: "Approximately 500 million tons of CO2", de: `Etwa 500 Millionen Tonnen CO2`},
    category3_question4_option2: {en: "Over 1000 million tons of CO2 ", de: "Über 1.000 Millionen Tonnen CO2"},
    category3_question4_option3: {en: "Over 3000 million tons CO2", de: "Über 3.000 Millionen Tonnen CO2"},
    category3_question5_option1: {en: "Single-use paper bags", de: "Einwegtüten aus Papier"},
    category3_question5_option2: {en: "Reusable plastic bags", de: "Mehrwegplastiktüten"},
    category3_question5_option3: {en: "Washable fabric bags that can be used numerous times ", de: "Waschbare Stoffbeutel, die mehrmals verwendet werden"},
    category3_question1_source: {en: "Photo: Brian Yurasits / unsplash.com", de: "Foto: Brian Yurasits / unsplash.com"},
    category3_question2_source: {en: "Photo: Varun Gaba / unsplash.com", de: "Foto: Varun Gaba / unsplash.com"},
    category3_question3_source: {en: "Photo: Martin Adams / unsplash.com", de: "Foto: Martin Adams / unsplash.com"},
    category3_question4_source: {en: "Photo: Martin Adams / unsplash.com", de: "Foto: Martin Adams / unsplash.com"},
    category3_question5_source: {en: "Photo: Lisa Fotios / pexels", de: "Foto: Lisa Fotios / pexels"},
    category3_question1_explanation: {en: "It is often assumed that cigarettes rot in the environment. But this is a misconception, since conventional cigarette filters contain plastic fibers (cellulose acetate) and are not biodegradable. In addition, cigarette butts contain numerous toxic substances that can subsequently be released into the environment.", de: "Oftmals wird davon ausgegangen, dass Zigaretten in der Umwelt verrotten. Doch das ist ein Irrtum, da herkömmliche Zigarettenfilter Kunststofffasern (Celluloseacetat) enthalten und nicht biologisch abbaubar sind. Außerdem enthalten Zigarettenkippen zahlreiche giftige Stoffe, die dann ebenfalls in die Umwelt gelangen können."},
    category3_question2_explanation: {en: "So many small plastic particles are released into the environment on car trips that tires end up about 1 – 1.5 kg lighter at the end of their average four-year lifespan. All in all, tire abrasion is the largest source of microplastics in Germany and, according to estimates, accounts for around a third of all microplastic emissions in Germany alone.", de: "Bei jeder Autofahrt lösen sich so viele kleine Kunststoffpartikel von den Reifen ab, dass PKW-Reifen am Ende ihres im Durchschnitt vierjährigen Lebens ca. 1 bis 1,5 Kilogramm weniger wiegen. In der Summe ist Reifenabrieb die größte Quelle von Mikroplastik in Deutschland und macht nach Schätzungen allein für Deutschland rund ein Drittel aller Mikroplastik- Emissionen aus."},
    category3_question3_explanation: {en: "In recent years, modern measuring techniques have enabled researchers to detect microplastics in every spot on earth, no matter how remote: in the deep sea, on mountain tops, even in the Arctic ice. Microplastics could also be detected in the air, in rainwater and in snow. The only place that the presence of microplastics has not yet been investigated is the moon.", de: "In den vergangenen Jahren konnten Forscher und Forscherinnen durch moderne Messtechniken Mikroplastik an jedem noch so entlegenen Ort der Erde nachweisen: in der Tiefsee, auf Bergspitzen und sogar im arktischen Eis. Mikroplastik konnte auch in der Luft, im Regenwasser und im Schnee nachgewiesen werden. Lediglich auf dem Mond wurde bislang noch nicht nach Mikroplastik gesucht.  "},
    category3_question4_explanation: {en: "In 2015, 1,085 million tons of CO2 were released worldwide through the production of plastics (calculated in CO2 equivalents). This represents 61% of the total greenhouse gases released by plastic, as the processing and disposal of plastic also releases CO2.", de: "Weltweit wurden im Jahr 2015 1.085 Mio. Tonnen CO2 durch die Herstellung von Plastik freigesetzt (berechnet in CO2-Äquivalenten). Dies stellt 61% der insgesamt durch Plastik freigesetzten Treibhausgase dar, denn auch Verarbeitung und die Entsorgung von Plastik setzen CO2 frei."},
    category3_question5_explanation: {en: "Single-use paper bags only shift the problem. Their production is very energy- and water-intensive. In addition, the global demand for wood for paper production puts a strain on forest ecosystems.", de: "Papiertüten, die nur einmal benutzt werden, verlagern nur das Problem. Ihre Herstellung ist sehr energie- und wasseraufwändig. Zudem belastet die globale Nachfrage nach Holz für die Papierproduktion die Ökosysteme der Wälder."},
    category3_question2_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 35"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 35"]},
    category3_question4_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 27"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 27"]},
    category4_question1: {en: "Why is it dangerous for many marine animals when plastic waste ends up in the sea?", de: " Warum ist Plastikmüll, der im Meer landet, für viele Meerestiere gefährlich?"},
    category4_question2: {en: "What are microplastics?", de: " Was ist Mikroplastik?"},
    category4_question3: {en: "What is plastic made of?", de: " Woraus wird Plastik hergestellt?"},
    category4_question4: {en: "What do I do with my plastic waste in the park when there is no garbage bin to be seen anywhere?", de: " Was mache ich mit meinem Plastikmüll im Park, wenn weit und breit kein Mülleimer zu sehen ist?"},
    category4_question5: {en: "What do the Plastic Pirates do?", de: " Was machen Kinder und Jugendliche, die bei den „Plastikpiraten“ mitmachen?"},
    category4_question1_option1: {en: "Animals think plastic waste is food, but cannot digest it.", de: "Tiere halten Plastikmüll für etwas zu fressen, können ihn dann aber nicht verdauen."},
    category4_question1_option2: {en: "Plastic waste develops a highly toxic effect when it comes in contact with salt water and everything within a radius of 2 meters dies.", de: "Plastikmüll entfaltet eine hochgiftige Wirkung bei Berührung mit Salzwasser und alles im Umkreis von 2 Metern stirbt ab."},
    category4_question1_option3: {en: "Animals are terribly frightened when they see the colorful plastic because they do not know what it is.", de: "Tiere erschrecken sich furchtbar, wenn sie das bunte Plastik sehen, weil sie nicht wissen, was das ist."},
    category4_question2_option1: {en: "Plastic particles that are so small that they can only be seen under a microscope.", de: "Plastikteilchen, die so klein sind, dass man sie nur unter dem Mikroskop sehen kann."},
    category4_question2_option2: {en: "All plastic bits that are about the size of iron-on beads or smaller. ", de: "Alle Plastikteile, die ungefähr so groß sind wie Bügelperlen oder kleiner."},
    category4_question2_option3: {en: "Soft plastic, which is created when hard plastic is melted in the microwave. ", de: "Weiches Plastik, das entsteht, wenn man hartes Plastik in der Mikrowelle schmilzt."},
    category4_question3_option1: {en: "From crude oil and natural gas. ", de: "Aus Erdöl und Erdgas."},
    category4_question3_option2: {en: "From the plant „Plasticala“.", de: "Aus der Pflanze Plastikala."},
    category4_question3_option3: {en: "From colorful rock crystals.", de: "Aus bunten Bergkristallen."},
    category4_question4_option1: {en: "If there is no garbage bin, I may just leave the trash on the ground.  ", de: "Wenn es keinen Mülleimer gibt, darf ich den Müll auf dem Boden liegen lassen."},
    category4_question4_option2: {en: "There are always garbage bins. I just have to look for them. ", de: "Es gibt immer Mülleimer, ich muss sie nur suchen."},
    category4_question4_option3: {en: "I take the trash home and throw it into the garbage bin. ", de: "Ich nehme den Müll mit nach Hause und werfe ihn dort in den Mülleimer."},
    category4_question5_option1: {en: "They raid ships carrying plastic in their cargo holds.", de: "Sie überfallen Schiffe, die Plastik in ihrer Fracht befördern."},
    category4_question5_option2: {en: "They go to rivers and examine how much plastic they find on land and in water.", de: "Sie gehen an Flüsse und untersuchen, wie viel Plastik sie an Land und im Wasser finden."},
    category4_question5_option3: {en: "They collect plastic waste from the sea and bring it ashore.", de: "Sie sammeln Plastikmüll aus dem Meer und bringen ihn an Land."},
    category4_question1_source: {en: "Photo: Blandine Joannic / pixabay", de: "Foto: Blandine Joannic / pixabay"},
    category4_question2_source: {en: "Photo: Tim Kiessling / Kieler Forschungswerkstatt", de: "Foto: Tim Kiessling / Kieler Forschungswerkstatt"},
    category4_question3_source: {en: "Photo: Greg Rosenke / unsplash.com", de: "Foto: Greg Rosenke / unsplash.com"},
    category4_question4_source: {en: "Photo: Mandy Hinzmann / Ecologic Institut", de: "Foto: Mandy Hinzmann / Ecologic Institut"},
    category4_question5_source: {en: "© BMBF/Plastic Pirates – Go Europe!", de: "© BMBF/Plastic Pirates – Go Europe!"},
    category4_question1_explanation: {en: "Whether enormous whales or tiny anchovies, sea dwellers often find it difficult to distinguish floating plastic particles from their normal food. Eating plastic particles is life-threatening for them, since they cannot digest plastic like they would algae and fish.The plastic stays in the stomach and over time the animals starve to death. They can also get entangled and injured in larger pieces of plastic.", de: "Ob große Wale oder winzige Sardellen – Meeresbewohner haben es häufig schwer, herumschwimmende Plastikteilchen von ihrem normalen Futter zu unterscheiden. Plastikteile zu fressen ist für sie lebensgefährlich, da sie Plastik nicht verdauen können. Das Plastik bleibt im Magen und mit der Zeit verhungern die Tiere. Außerdem können sie sich in größeren Plastikteilen verstricken und verletzen."},
    category4_question2_explanation: {en: "Microplastics measure between one micrometer and five millimeters in size. This means they can be tiny and invisible or as large as iron-on beads and visible to the human eye. Small plastic bits are often present in our everyday lives without us even recognizing them, for example in shower gels and shampoos.", de: "Mikroplastik ist zwischen einem Mikrometer und fünf Millimetern groß. Somit kann es winzig klein und unsichtbar, aber auch so groß wie Bügelperlen und für das menschliche Auge erkennbar sein. Die kleinen Plastikteile kommen in unserem Alltag häufig vor, ohne dass wir sie erkennen, zum Beispiel in Duschgels und Shampoos oder Kaugummis."},
    category4_question3_explanation: {en: "Plastic is not a natural material on earth. It is artificially produced by humans. The raw materials for the production of plastic are mainly oil and natural gas. But plastic can also be made from renewable resources such as wood, corn or sugar cane. Then it is called bio-based plastic or bio-plastic. ", de: "Plastik kommt nicht als natürliches Material auf der Erde vor. Es wird vom Menschen künstlich hergestellt – daher wird es auch als Kunststoff bezeichnet. Die Rohstoffe für die Herstellung von Plastik sind hauptsächlich Erdöl und Erdgas. Aber auch aus nachwachsenden Rohstoffen wie Holz, Mais oder Zuckerrohr lässt sich Plastik herstellen. Dann bezeichnet man es als biobasierten Kunststoff.  "},
    category4_question4_explanation: {en: "In some parks there is no garbage bin nearby or the containers are full up. The best thing to do is to take the plastic garbage home and throw it away there. Plastic does not decompose in nature and would therefore be there for a long time.", de: "In manchen Parks gibt es keinen Mülleimer in der Nähe oder der Mülleimer ist voll. Am besten ist es dann, den Plastikmüll mit nach Hause zu nehmen und dort wegzuwerfen. Denn Plastik kann in der Natur nicht abgebaut werden und würde daher lange Zeit dort verbleiben."},
    category4_question5_explanation: {en: "The Plastic Pirates are young people between 10 and 16 years of age who go on expeditions in a group or with their school class. They investigate in river basins how much plastic waste is found on land and in the water. In this way, they support scientists to research plastic pollution in water. Further information at: https://bmbf-plastik.de/en/plasticpirates", de: "Die Plastikpiraten sind Jugendliche zwischen 10 und 16 Jahren, die mit ihrer eigenen Gruppe oder Schulklasse auf Expedition gehen. An Flüssen erforschen sie, wie viel Plastikmüll sie an Land und im Wasser finden. Auf diese Weise unterstützen sie Wissenschaftler und Wissenschaftlerinnen dabei, die Plastikverschmutzung in Gewässern zu erforschen."},
    category4_question3_referenceText: {en: ["Heinrich-Böll-Stiftung & BUND 2019, p. 10"], de: ["Heinrich-Böll-Stiftung & BUND 2019, S. 10"]},
    category4_question4_referenceText: {en: ["More info in the following report of \"Sendung mit der Maus\""], de: ["Mehr Infos in einem Beitrag der Sendung mit der Maus"]},
    category4_question5_referenceText: {en: ["More info on the project “Plastic Pirates - Go Europe!”"], de: ["Weitere Infos zum Projekt Plastic Pirates - Go Europe!"]},
    category4_question5_referenceLink: {en: ["https://www.plastic-pirates.eu/en"], de: ["https://www.plastic-pirates.eu/de"]},
    category5_question1: {en: "What is chemical recycling?", de: " Was ist chemisches Recycling?"},
    category5_question2: {en: "How many different types of plastics are known today?  ", de: " Wie viele verschiedene Kunststoffarten sind heute bekannt?"},
    category5_question3: {en: 'The use of so-called "fairy dust" could help to make our use of plastic products more sustainable in the future. What is the idea here? ', de: " Der Einsatz von sogenannten „Feenstaub“ könnte zukünftig dazu beitragen, unsere Nutzung von Plastikprodukten nachhaltiger zu machen. Was ist die Idee dabei?"},
    category5_question4: {en: "What are the advantages of ship-based treatment of plastics?", de: " Welchen Vorteil bietet die schiffgestützte Behandlung von Kunststoffen?"},
    category5_question5: {en: "How does a Finish start-up company fight the mass use of disposable packaging in online retail?", de: " Wie sagt ein finnisches Start-Up den massenhaft verwendeten Einwegverpackungen im Online-Handel den Kampf an?"},
    category5_question1_option1: {en: "The reprocessing of chemical waste from industry.", de: "Die Wiederaufbereitung von Chemikalienabfällen aus der Industrie."},
    category5_question1_option2: {en: "The recovery of the source materials of plastic waste by chemical processes.", de: "Die Wiedergewinnung der Ausgangsstoffe von Kunststoffabfällen durch chemische Prozesse."},
    category5_question1_option3: {en: " The chemical cleaning of old plastics in order to reuse them.", de: "Die chemische Reinigung von Altkunststoffen, um diese wiederzuverwenden."},
    category5_question2_option1: {en: "Well over 200 ", de: "Weit über 200"},
    category5_question2_option2: {en: "Just under 100", de: "Knapp unter 100"},
    category5_question2_option3: {en: "Over 50", de: "Über 50"},
    category5_question3_option1: {en: "By adding “fairy dust”, the plastic product decomposes by itself after use, so that waste treatment is no longer necessary.", de: "Durch die Beigabe von Feenstaub zersetzt sich das Kunststoffprodukt nach der Nutzung von allein, sodass die Abfallbehandlung eingespart werden kann."},
    category5_question3_option2: {en: "“Fairy dust” can be added to environmentally friendly types of plastic so that customers can identify them more easily. ", de: "Feenstaub kann umweltfreundlichen Plastiksorten beigemischt werden, sodass die Kundinnen und Kunden diese leichter erkennen können."},
    category5_question3_option3: {en: "“Fairy dust” enables sensors to detect the same types of plastic so that plastic waste can be better sorted.", de: "Feenstaub ermöglicht es Sensoren, gleiche Kunststoffarten zu erkennen, sodass Abfälle aus Kunststoff besser sortiert werden können."},
    category5_question4_option1: {en: "It burns plastic waste floating around and uses the energy gained directly.", de: "Es verbrennt herumschwimmenden Plastikmüll und nutzt die gewonnene Energie direkt."},
    category5_question4_option2: {en: "It enables the recycling of plastic waste in regions where recycling structures on land are lacking.", de: "Es ermöglicht die Verwertung von Plastikmüll in Regionen, wo Recyclingstrukturen an Land fehlen."},
    category5_question4_option3: {en: "It collects plastic waste from the sea and takes it directly to the nearest recycling plant.", de: "Es sammelt Plastikmüll aus dem Meer und bringt es direkt zur nächstgelegenen Recyclinganlage."},
    category5_question5_option1: {en: "It appeals for more local shopping in stores again.", de: "Es appelliert daran, wieder mehr vor Ort in Geschäften einzukaufen."},
    category5_question5_option2: {en: "It has developed a reusable system for shipping packaging.", de: "Es hat ein Mehrwegsystem für Versandverpackungen entwickelt."},
    category5_question5_option3: {en: "It has developed biodegradable shipping packaging that can be disposed of in the organic waste bin.", de: "Es hat bioabbaubare Versandverpackungen entwickelt, die in der Biomülltonne entsorgt werden können."},
    category5_question1_source: {en: "Photo: Carsten Eichert / Rittec", de: "Foto: Carsten Eichert / Rittec"},
    category5_question2_source: {en: "Photo: Lena Aebli / Ecologic Institut", de: "Foto: Lena Aebli / Ecologic Institut"},
    category5_question3_source: {en: "Photo: Polysecure GmbH", de: "Foto: Polysecure GmbH"},
    category5_question4_source: {en: "Photo: KuWert", de: "Foto: KuWert"},
    category5_question5_source: {en: "Photo: RePack", de: "Foto: RePack"},
    category5_question1_explanation: {en: "In chemical recycling, plastic waste is broken down into its basic components. These can then be used as the basic material for new plastic. In this way, plastic mixtures can also be recycled to a high standard. In Germany, many scientists are researching the possibilities of chemical recycling.", de: "Beim chemischen Recycling werden Kunststoffabfälle in ihre Grundbestandteile zerlegt. Diese können dann als Ausgangsstoff für neues Plastik verwendet werden. Auf diese Weise können auch Kunststoffgemische hochwertig recycelt werden. In Deutschland forschen verschiedene Wissenschaftlerinnen und Wissenschaftler an den Möglichkeiten des chemischen Recyclings."},
    category5_question2_explanation: {en: "Today, considerably more than 200 different types of plastics are known. Plastics can vary greatly in their properties and applications. In addition to external characteristics, there are differences in heat resistance, elasticity, durability and breaking strength. New plastics with special properties are constantly being developed.", de: "Heute sind weit mehr als 200 verschiedene Kunststoffarten bekannt. Kunststoffe können sich stark unterscheiden in ihren Eigenschaften und Einsatzmöglichkeiten. Neben äußeren Merkmalen gibt es Unterschiede in Sachen Hitzebeständigkeit, Elastizität, Widerstandsfähigkeit und Bruchfestigkeit. Es werden immer wieder neue Kunststoffe mit speziellen Eigenschaften entwickelt."},
    category5_question3_explanation: {en: 'With fluorescent markers (so-called "fairy dust"), plastic products can be marked in such a way that they can be clearly separated by type in sorting plants, optimising the recycling process.', de: "Mit fluoreszierenden Markern, sogenanntem „Feenstaub“, können Kunststoffprodukte so markiert werden, dass sie in Sortieranlagen eindeutig nach Typus getrennt und besser recycelt werden können."},
    category5_question4_explanation: {en: "A recycling plant for plastics, which is very similar to a land-based facility, is installed on this platform ship. This makes waste collection and treatment available in countries that would ordinarily lack the infrastructure for plastic recycling. Plastic waste can be recycled on the ship and the plastic is returned to the global value chain.", de: "Auf diesem Plattformschiff (Modell) ist eine Recyclinganlage für Kunststoffe installiert, die sich kaum von landgestützten Anlagen unterscheidet. Auf diese Weise werden Strukturen der Abfallsammlung und -behandlung bereitgestellt, die in Ländern mit geringem Einkommen oft fehlen. Kunststoffabfälle können auf dem Schiff verwertet werden und das Plastik gelangt zurück in den globalen Wertschöpfungskreislauf."},
    category5_question5_explanation: {en: 'The Finnish start-up company "RePack" has developed resistant reusable packaging for the online mail order business. The materials consist of recycled plastic, can be folded to letter size and returned by mail. Compared to one-way packaging made of plastic or cardboard, this already saves CO2 emissions after two uses.', de: "Das finnische Start-Up-Unternehmen „RePack“ hat widerstandsfähige Mehrwegverpackungen für den Online-Versandhandel entwickelt. Sie bestehen aus recyceltem Kunststoff, können auf Briefgröße zusammengefaltet und per Post zurückgesendet werden. Verglichen mit Einwegverpackungen aus Kunststoff oder Pappe werden so CO2-Emissionen eingespart – und das bereits nach zweimaliger Nutzung."},

};