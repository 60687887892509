import * as illustration1 from "../Assets/images/answer images/Plastik im Alltag/01_fleece980.jpg"
import * as illustration2 from "../Assets/images/answer images/Plastik im Alltag/02_lyocell980.jpg"
import * as illustration3 from "../Assets/images/answer images/Plastik im Alltag/03_MaxPixel.net980.jpg"
import * as illustration4 from "../Assets/images/answer images/Plastik im Alltag/04_Plastikflaschen980.jpg"
import * as illustration5 from "../Assets/images/answer images/Plastik im Alltag/05_Plastikbesteck980.jpg"
import {quiz} from "../Translator/content";

export const QuestionSet_PlastikImAlltag = {
    Category: quiz.category1Name,
    questionSet: [
        {//1
            question: quiz.category1_question1,
            options: [quiz.category1_question1_option1, quiz.category1_question1_option2, quiz.category1_question1_option3],
            answer: 3,
            solution: {
                illustration: illustration1,
                illustrationSource: quiz.category1_question1_source,
                explanation: {
                    text: quiz.category1_question1_explanation,
                    images: [],
                    references: {
                        text: quiz.category1_question1_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//2
            question: quiz.category1_question2,
            options: [quiz.category1_question2_option1, quiz.category1_question2_option2, quiz.category1_question2_option3],
            answer: 2,
            solution: {
                illustration: illustration2,
                illustrationSource: quiz.category1_question2_source,
                explanation: {
                    text: quiz.category1_question2_explanation,
                    images: [],
                    references: {
                        text: ["Utopia 2020"],
                        link: ["https://utopia.de/ratgeber/tencel-das-ist-das-besondere-an-diesem-stoff/"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//3
            question: quiz.category1_question3,
            options: [quiz.category1_question3_option1, quiz.category1_question3_option2, quiz.category1_question3_option3],
            answer: 2,
            solution: {
                illustration: illustration3,
                illustrationSource: quiz.category1_question3_source,
                explanation: {
                    text: quiz.category1_question3_explanation,
                    images: [],
                    references: {
                        text: quiz.category1_question3_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//4
            question: quiz.category1_question4,
            options: [quiz.category1_question4_option1, quiz.category1_question4_option2, quiz.category1_question4_option3],
            answer: 2,
            solution: {
                illustration: illustration4,
                illustrationSource: quiz.category1_question4_source,
                explanation: {
                    text: quiz.category1_question4_explanation,
                    images: [],
                    references: {
                        text: quiz.category1_question4_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//5
            question: quiz.category1_question5,
            options: [quiz.category1_question5_option1, quiz.category1_question5_option2, quiz.category1_question5_option3],
            answer: 1,
            solution: {
                illustration: illustration5,
                illustrationSource: quiz.category1_question5_source,
                explanation: {
                    text: quiz.category1_question5_explanation,
                    images: [],
                    references: {
                        text: ["Mederake, Hinzmann & Langsdorf 2020"],
                        link: ["https://bmbf-plastik.de/sites/default/files/2020-08/20-08-04_Hintergrundpapier_Plastikpolitik-final.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
    ]
};