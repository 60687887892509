import * as illustration1 from "../Assets/images/answer images/Fragen für Kinder/01_Moewe_Blandine JOANNIC auf Pixabay980.jpg"
import * as illustration2 from "../Assets/images/answer images/Fragen für Kinder/02_Tim Kiessling KielerForschungswerkstatt_quer980.jpg"
import * as illustration3 from "../Assets/images/answer images/Fragen für Kinder/03_GregRosenke_unsplash_quer980.jpg"
import * as illustration4 from "../Assets/images/answer images/Fragen für Kinder/04_MandyHinzmann_quer980.jpg"
import * as illustration5 from "../Assets/images/answer images/Fragen für Kinder/05_PlastipiratenLogo_FINAL300dpi980.jpg"
import {quiz} from "../Translator/content";

export const QuestionSet_FragenFurKinder = {
    Category: quiz.category4Name,
    questionSet: [
        {//1
            question: quiz.category4_question1,
            options: [quiz.category4_question1_option1, quiz.category4_question1_option2, quiz.category4_question1_option3],
            answer: 1,
            solution: {
                illustration: illustration1,
                illustrationSource: quiz.category4_question1_source,
                explanation: {
                    text: quiz.category4_question1_explanation,
                    images: [],
                    references: {
                        text: ["NABU 2018"],
                        link: ["https://www.nabu.de/imperia/md/content/nabude/meeresschutz/19-05-mu__llkippe_meer-5-2019-final.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//2
            question: quiz.category4_question2,
            options: [quiz.category4_question2_option1, quiz.category4_question2_option2, quiz.category4_question2_option3],
            answer: 2,
            solution: {
                illustration: illustration2,
                illustrationSource: quiz.category4_question2_source,
                explanation: {
                    text: quiz.category4_question2_explanation,
                    images: [],
                    references: {
                        text: ["BUND 2020"],
                        link: ["https://www.bund.net/service/publikationen/detail/publication/bund-einkaufsratgeber-mikroplastik/"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//3
            question: quiz.category4_question3,
            options: [quiz.category4_question3_option1, quiz.category4_question3_option2, quiz.category4_question3_option3],
            answer: 1,
            solution: {
                illustration: illustration3,
                illustrationSource: quiz.category4_question3_source,
                explanation: {
                    text: quiz.category4_question3_explanation,
                    images: [],
                    references: {
                        text: quiz.category4_question3_referenceText,
                        link: ["https://www.boell.de/sites/default/files/2019-11/Plastikatlas_2019_3._Auflage.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//4
            question: quiz.category4_question4,
            options: [quiz.category4_question4_option1, quiz.category4_question4_option2, quiz.category4_question4_option3],
            answer: 3,
            solution: {
                illustration: illustration4,
                illustrationSource: quiz.category4_question4_source,
                explanation: {
                    text: quiz.category4_question4_explanation,
                    images: [],
                    references: {
                        text: quiz.category4_question4_referenceText,
                        link: ["https://www.wdrmaus.de/filme/sachgeschichten/plastikmuell_im_meer.php5"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//5
            question: quiz.category4_question5,
            options: [quiz.category4_question5_option1, quiz.category4_question5_option2, quiz.category4_question5_option3],
            answer: 2,
            solution: {
                illustration: illustration5,
                illustrationSource: quiz.category4_question5_source,
                explanation: {
                    text: quiz.category4_question5_explanation,
                    images: [],
                    references: {
                        text: quiz.category4_question5_referenceText,
                        link: quiz.category4_question5_referenceLink
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
    ]
};