import * as logo from "../Assets/images/logo/PlastikNet@72ppi_0.jpg";
import * as illustration1 from "../Assets/images/answer images/Innovationen rund um Plastik/01_Chem_RC_Rittec_quer980.jpg"
import * as illustration2 from "../Assets/images/answer images/Innovationen rund um Plastik/02plastikarten980.jpg"
import * as illustration3 from "../Assets/images/answer images/Innovationen rund um Plastik/03_TBSFluoreszenzmarker in Flaschen_Polysecure_GmbH980.jpg"
import * as illustration4 from "../Assets/images/answer images/Innovationen rund um Plastik/04_Platform 10_980.jpg"
import * as illustration5 from "../Assets/images/answer images/Innovationen rund um Plastik/05_RePack_M_Yellow_Group_Transparent-2980.jpg"
import {quiz} from "../Translator/content";

export const QuestionSet_InnovationRundUmPlastik = {
    Category: quiz.category5Name,
    questionSet: [
        {//1
            question: quiz.category5_question1,
            options: [quiz.category5_question1_option1, quiz.category5_question1_option2, quiz.category5_question1_option3],
            answer: 2,
            solution: {
                illustration: illustration1,
                illustrationSource: quiz.category5_question1_source,
                explanation: {
                    text: quiz.category5_question1_explanation,
                    images: [],
                    references: {
                        text: ["ResolVe Projekt"],
                        link: ["https://bmbf-plastik.de/sites/default/files/2018-12/Infoblatt_Resolve_2018_p.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//2
            question: quiz.category5_question2,
            options: [quiz.category5_question2_option1, quiz.category5_question2_option2, quiz.category5_question2_option3],
            answer: 1,
            solution: {
                illustration: illustration2,
                illustrationSource: quiz.category5_question2_source,
                explanation: {
                    text: quiz.category5_question2_explanation,
                    images: [],
                    references: {
                        text: ["VDI Wissensforum (ohne Jahr)"],
                        link: ["https://www.vdi-wissensforum.de/weiterbildung-kunststoff/kunststoffarten/"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//3
            question: quiz.category5_question3,
            options: [quiz.category5_question3_option1, quiz.category5_question3_option2, quiz.category5_question3_option3],
            answer: 3,
            solution: {
                illustration: illustration3,
                illustrationSource: quiz.category5_question3_source,
                explanation: {
                    text: quiz.category5_question3_explanation,
                    images: [],
                    references: {
                        text: ["Woidasky et al. 2020"],
                        link: ["https://www.researchgate.net/publication/339637432_Kunststoffidentifikation_und_-sortierung_in_der_Circular_Economy_durch_Fluoreszenzmarker"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//4
            question: quiz.category5_question4,
            options: [quiz.category5_question4_option1, quiz.category5_question4_option2, quiz.category5_question4_option3],
            answer: 2,
            solution: {
                illustration: illustration4,
                illustrationSource: quiz.category5_question4_source,
                explanation: {
                    text: quiz.category5_question4_explanation,
                    images: [],
                    references: {
                        text: ["KuWert Projekt"],
                        link: ["http://www.kuwert.hs-bremen.de/downloads/2020-03-PR-KuWert-nachhaltig-gegen-Plastik-Umwelt-de.pdf"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
        {//5
            question: quiz.category5_question5,
            options: [quiz.category5_question5_option1, quiz.category5_question5_option2, quiz.category5_question5_option3],
            answer: 2,
            solution: {
                illustration: illustration5,
                illustrationSource: quiz.category5_question5_source,
                explanation: {
                    text: quiz.category5_question5_explanation,
                    images: [],
                    references: {
                        text: ["Deutschlandfunk 2019"],
                        link: ["https://www.deutschlandfunk.de/alternative-zum-plastik-mehrweg-verpackungen-fuer-den.769.de.html?dram:article_id=444036"]
                    }
                }
            },
            answered: false, wrongInTheFirstTry: undefined, correctInTheFirstTry: undefined,
        },
    ]
};