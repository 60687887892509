import React, {Component} from 'react';
import './Main.css';
import Header from "./Components/Header/Header";
import Quiz from "./Components/Quiz/Quiz";
import Footer from "./Components/Footer/Footer";
import backgroundImage from "./Components/Assets/images/background/shutterstock_309809561.jpg"
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette:{
        primary:{
            main: '#006E9E'
        }
    }
})

class Main extends Component{

    constructor(props) {
        super(props);
        this.state = {
            language: "de",
        }
    }

    changeState = (stateField, value) => {
        this.setState({[stateField]: value});
    };


    render() {
        return (
            <ThemeProvider theme={theme}>
                <div className="App" style={{backgroundImage: `url(${backgroundImage})`, width: "100%", height: "fit-content", position: "absolute", backgroundSize: "cover", overflow: "auto"}}>
                    <Header language={this.state.language} switchLanguage={(field, value) => this.changeState(field, value)}/>
                    <main role={"main"}>
                        <Quiz language={() => this.state.language}/>
                    </main>
                    <Footer language={this.state.language}/>
                </div>
            </ThemeProvider>
        );
    }
}

export default Main;
